import React, { useCallback, useMemo, useState } from 'react';
import axios from 'axios';
import cx from 'classnames';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Button from '../../components/Button';
import { cleanTelStr, Input, TelInput } from '../../components/FormControls';
import Layout from '../../components/Layout';
import SEO from '../../components/SEO';

import OrangeImage from '../../components/images/JuppukOrangeImage';
import RayImage from '../../components/images/JuppukRayImage';
import RayTalking from '../../components/images/JuppukRayTalkingImage';

import styles from '../juppuk-new-years.module.scss';

const JuppukNewYearsPage = ({ location }) => {
  const [enquireForm, setEnquireForm] = useState({
    name: '',
    tel: ''
  });
  const [isEnquiring, setIsEnquiring] = useState(false);
  const [isEnquireSuccess, setIsEnquireSuccess] = useState(false);

  const shouldAllowSubmit = useMemo(
    () => !!(enquireForm.name.trim() && cleanTelStr(enquireForm.tel).length === 8 && !isEnquiring),
    [enquireForm.name, enquireForm.tel, isEnquiring]
  );

  const submit = useCallback(async () => {
    if (isEnquiring || isEnquireSuccess || !shouldAllowSubmit) {
      return;
    }

    setIsEnquiring(true);

    try {
      const { name, tel } = enquireForm;
      await axios({
        method: 'post',
        url: '/api/contact',
        data: {
          name: name.trim(),
          tel: cleanTelStr(tel)
        }
      });
      setIsEnquireSuccess(true);
    } catch (err) {
      setIsEnquiring(false);
    }
  }, [
    enquireForm.name,
    enquireForm.tel,
    isEnquiring,
    isEnquireSuccess,
    shouldAllowSubmit,
    setIsEnquiring,
    setIsEnquireSuccess
  ]);

  const onChange = useCallback(
    ({ target }) => {
      const { name, value } = target;
      setEnquireForm({ ...enquireForm, [name]: value });
    },
    [enquireForm.name, enquireForm.tel, setEnquireForm]
  );

  return (
    <Layout className={styles.root} disablePromoPopup location={location} navAlwaysFixed showI18n>
      <SEO title="DOUBLE STORAGE 儲存 X 收納服務優惠" />
      <section className={styles.lead}>
        <div className={styles.title}>
          <div>Jointly introducing</div>
          <h1>X2 DOUBLE STORAGE</h1>
        </div>
        <div className={cx(styles.companyName, styles.juppuk)}>
          <div className={styles.zh}>執屋</div>
          <div className={styles.en}>JuppUk</div>
        </div>
        <div className={cx(styles.companyName, styles.roomyr)}>
          <div className={styles.zh}>儲方</div>
          <div className={styles.en}>Roomyr</div>
        </div>
        <div className={styles.orange}>
          <OrangeImage />
        </div>
        <div className={styles.ray}>
          <RayImage />
        </div>
      </section>
      <section className={styles.intro}>
        <div className={styles.wrapper}>
          <div className={styles.bubble}>
            <span>
              We wish you all a Happy New Year! It has been a while since our last update, so we now present you with a
              big surprise! Roomyr is partnering with the mighty and famous de-cluttering master
            </span>
            <span className={styles.highlight}> Orange</span>
            <span>！</span>
          </div>
          <div className={styles.rayTalking}>
            <RayTalking />
          </div>
        </div>
      </section>
      <section className={styles.details}>
        <div className={styles.wrapper}>
          <p>
            Roomyr and Juppuk's Orange are partnering together for a limited-time-only product: Double Storage and
            De-cluttering Package！ Is your end-of-year cleaning and re-organizing too frustrating? Do not worry! Orange
            is here to help! With her professional de-cluttering expertise and our storage and delivery services, our
            new package is the perfect all-in-one service to help you with your home re-organization.
          </p>

          <p>
            Roomyr's senior manager Ray and Juppuk's Orange will closely work with you every step of the way. Give us a
            call at:
          </p>

          <a href="tel:21113113" className={styles.phone}>
            <FontAwesomeIcon fixedWidth icon={['fal', 'phone']} />
            <span>2111 3113</span>
          </a>

          <p style={{ textAlign: 'center' }}>Act fast! We have limited availability!</p>
        </div>
      </section>
      <section className={styles.enquire}>
        <div className={styles.wrapper}>
          <h2 className={styles.title}>Enquiries？</h2>
          <p>Please enter your information below and we will get back to you in 24 hours.</p>
          <div className={styles.form}>
            <Input name="name" label="Name" value={enquireForm.name} onChange={onChange} />
            <TelInput name="tel" label="Phone" value={enquireForm.tel} disabled={isEnquiring} onChange={onChange} />
            <Button tabIndex={0} disabled={!shouldAllowSubmit} onClick={submit}>
              Contact us
            </Button>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default JuppukNewYearsPage;
